<template>
    <div id="app-dropdown" class="card dropdown-content hide-on-small-and-down appdropdown right z-depth-1-half" tabindex="0">
        <div class="card-content text-left">
            <span class="card-title grey-text text-darken-4 center">
                <img src="https://core.lukas-bownik.net/images/pika-logos/pikacloud_apps.svg" alt="Pika"/>
            </span>
            <div class="collection borderless-collection">
                <a class="collection-item navlink app-menu-item" href="https://cloud.lukas-bownik.net/" title="Pika Cloudfront">
                    <span class="material-symbols-outlined secondary-content navlink havelock-text">
                        cloud
                    </span>
                    Pika Cloudfront
                </a>
                <a class="collection-item navlink app-menu-item" href="https://core.lukas-bownik.net/" title="Pika Core">
                    <span class="material-symbols-outlined secondary-content navlink havelock-text">
                        storage
                    </span>
                    Pika Core
                </a>
                <a class="collection-item navlink app-menu-item" href="https://cloud.lukas-bownik.net/status" title="Pika Status">
                    <span class="material-symbols-outlined secondary-content navlink havelock-text">
                        vital_signs
                    </span>
                    Pika Status
                </a>
            </div>
        </div>
    </div>
</template>